import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

firebase.initializeApp({
  apiKey: "AIzaSyAur-9GqPJuiEe9sFEBWPW7NXOz0CKFZls",
  authDomain: "richardwilkosz-food-chooser.firebaseapp.com",
  projectId: "richardwilkosz-food-chooser",
  storageBucket: "richardwilkosz-food-chooser.appspot.com",
  messagingSenderId: "139572470934",
  appId: "1:139572470934:web:610a3655b28920d72f4eb7",
  measurementId: "G-0G89XHKZDK"
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
