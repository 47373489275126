import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { TestData } from '../helpers/TestData';
import { Restaurant } from '../models/Restaurant.model';
import VoteCard from './VoteCard';

// const useStyles = makeStyles({
//   root: {
//     maxWidth: 345,
//   },
//   media: {
//     height: 140,
//   },
// });

function getRandomInt(max: number): number {
  return Math.floor(Math.random() * max);
}

export default function VoteForm() {
  // const classes = useStyles();
  let dataSource = new TestData();

  const rngOne = getRandomInt(8);
  let rngTwo = getRandomInt(8);

  while (rngOne === rngTwo) {
    rngTwo = getRandomInt(8);
  }

  const restaurantOne: Restaurant = dataSource.restaurants[rngOne];
  const restaurantTwo: Restaurant = dataSource.restaurants[rngTwo];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <VoteCard name={restaurantOne.name} type={restaurantOne.type} todaysHours={restaurantOne.todaysHours} photo={restaurantOne.photo}></VoteCard>
      </Grid>
      <Grid item xs={12} sm={6}>
        <VoteCard name={restaurantTwo.name} type={restaurantTwo.type} todaysHours={restaurantTwo.todaysHours} photo={restaurantTwo.photo}></VoteCard>
      </Grid>
      <hr></hr>
    </Grid>
  );
}