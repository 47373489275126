import { Restaurant } from '../models/Restaurant.model.js';

export class TestData {
  restaurants: Array<Restaurant>;

  constructor() {
    this.restaurants = [
      {
        name: 'Bamboo House',
        type: 'Chinese',
        todaysHours: '11AM–9PM',
        photo: 'bamboo-house.jpg',
      },
      {
        name: 'Dosirak',
        type: 'Korean',
        todaysHours: '3:30PM–9:30PM',
        photo: 'dosirak.jpg',
      },
      {
        name: 'El Molcajete',
        type: 'Mexican',
        todaysHours: '11AM–9PM',
        photo: 'molcajete.png',
      },
      {
        name: 'Father Fats',
        type: 'Fine Dining',
        todaysHours: '11AM–7PM',
        photo: 'father-fats.jpg',
      },
      {
        name: 'Mikey\'s',
        type: 'Restaurant',
        todaysHours: '11AM–12AM',
        photo: 'mikeys.jpg',
      },
      {
        name: 'PJ\'s – Sentryworld',
        type: 'American',
        todaysHours: '11AM–10PM',
        photo: 'pjs.jpg',
      },
      {
        name: 'Watchman\'s Gone',
        type: 'American',
        todaysHours: '4PM–9PM',
        photo: 'watchmans-gone.jpg',
      },
      {
        name: 'Wicked Willow',
        type: 'Vegan',
        todaysHours: '11AM–7PM',
        photo: 'wicked-willow.jpeg',
      },
    ]
  }
}