import React from 'react';
import './App.css';
import { colors, CssBaseline, Container } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import AppStepper from './components/AppStepper';
import FoodChooserAppBar from './components/FoodChooserAppBar';

// Create a theme instance.
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: colors.orange.A400,
    },
    secondary: {
      main: '#1b998b',
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <div className="App">
        <FoodChooserAppBar></FoodChooserAppBar>
        <Container maxWidth="xl">
          <AppStepper></AppStepper>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
